import { Wrapper } from "@googlemaps/react-wrapper";
import PriceComparison from "./price-comparison/price-comparison";
import { loadGoogleMapsScript } from "./scripts/loadGoogleMapsScript";

export default function Root(props) {
    let googleMapsApiKey = loadGoogleMapsScript();
  return (
    <Wrapper apiKey={googleMapsApiKey}>
      <PriceComparison parentDataRef={props.compRef} />
    </Wrapper>
  );
}
